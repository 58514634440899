<template>
  <div class="authorization">
    <div>
      <div class="logo">
        <img src="./img/logo.svg" />
      </div>
      <div class="authorization-title">
        <h2>
          <!-- 是否允许访问？ -->
          {{ $t('c2230') }}
        </h2>
      </div>
      <div class="authorization-content">
        <el-card class="box-card">
          <h3>
            <!-- CLoudCC-api-auth2.0(Code)正在要求： -->
            {{ $t('c2231') }}
          </h3>
          <ul>
            <li v-for="(item,index) in list" :key="index">
              {{ $t(item.title) }}
            </li>
          </ul>
          <div>
            <!-- 是否需要访问{{ username }}？ -->
            {{ $t('c2232',[username]) }}
            (<a class="link" @click="tologin">
              <!-- 不是您? -->
              {{ $t('c2213') }}
            </a>)
          </div>
          <div class="button-group">
            <el-button @click="allow('false')">
              <!-- 拒绝 -->
              {{ $t('label.refuse') }}
            </el-button>
            <el-button type="primary" @click="allow('true')">
              <!-- 允许 -->
              {{ $t('c2212') }}
            </el-button>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
// 授权页面
import * as thirdLoginAuthApi from "../api";
export default {
  name: "authorization",
  data() {
    return {
      username: "aaaa@cloudcc.com",
      list: [
        {
          // title: "访问身份URL服务",
          title: "c2233",
        },
        {
          // title: "通过API管理用户数据",
          title: "c2234",
        },
      ],
    };
  },
  mounted() {
    this.username = this.$route.query.username;
    // 隐藏加载框
    document.getElementById("lightning-loading").style.display = "none";
  },
  methods: {
    /**
     * 对象转formdata格式 方法
     * @param  obj 对象数据
     * @returns  转为formdata格式的数据
     */
    jsToFormData(obj) {
      const formData = new FormData();
      Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof Array) {
          obj[key].forEach((item) => {
            formData.append(key, item);
          });
          return;
        }
        formData.append(key, obj[key]);
      });
      return formData;
    },
    //  授权
    allow(isAllow) {
      let params = {
        state: this.$route.query.state,
        accessToken: this.$route.query.token,
        user_oauth_approval: isAllow,
        orgId: this.$route.query.orgId || ""
      };
      thirdLoginAuthApi
        .thirdPartyLoginAuth(this.jsToFormData(params))
        .then((res) => {
          if (res.data != null) {
            this.$message.warning(res.data.returnInfo);
          } else {
            // 授权成功
            let headers = res.headers;
            if (headers.enableredirect == "true") {
              window.location.href = `${headers.url}?state=${headers.state}&code=${headers.code}`;
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    tologin() {
      this.$router.push({
        path: "/thirdLogin",
        query: {
          state: this.$route.query.state,
          orgId: this.$route.query.orgId || ""
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.authorization {
  width: 100%;
  height: 100vh;
  background: rgba(245, 246, 249, 0.48);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul,
  li {
    list-style-type: disc;
  }
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 300px;
    }
  }
  .authorization-title {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .authorization-content {
    width: 100%;
    display: flex;
    justify-content: center;
    .box-card {
      width: 400px;
      ul {
        margin: 14px 0;
        padding: 0 0 0 40px;
      }
      .link {
        color: #409eff;
        cursor: pointer;
      }
      .button-group {
        margin: 30px 0;
        display: flex;
        justify-content: space-around;
        ::v-deep.el-button {
          padding: 12px 50px !important;
        }
      }
    }
  }
}
</style>
